<template>
  <div class="pages">
    <van-sticky>
      <header class="nav-bar">
        <div @click="newAppBack" class="nav-bar-left"><van-icon name="arrow-left" /></div>
        <div class="nav-bar-title">查看用户运动方案</div>
        <div class="nav-bar-right">
          <div @click="createUserPlan" class="create-btn row-center-center bold">新建运动规划</div>
        </div>
      </header>
    </van-sticky>

    <div class="list">
      <div v-if="curCase" @click="goUserPlanDetail(curCase)" class="item row-start-start">
        <div class="row-between-center item-left-box">
          <div class="item-left">
            <div class="row-between-center">
              <h3 class="f14">{{ curCase.months }}个月 · 每周{{ curCase.weekCount }}次</h3>
              <div @click="getShare(curCase)" class="share-btn row-center-center bold">微信分享</div>
            </div>
            <p class="color3">
              <span class="health-tag" v-for="label in curCase.labelList" :key="label">{{ label }}</span>
            </p>
            <div class="create-date row-start-end">
              <img :src="curCase.createTeacherPic" alt="">
              <span>{{ curCase.createTeacherName }} 创建于 {{ curCase.startTime }} (方案时效{{ curCase.useDays }}天)</span>
            </div>
          </div>
        </div>
      </div>



      <div v-if="history.length" class="separate bold">──以下为历史方案报告──</div>

      <van-list v-model="loading" :finished="finished" @load="getList" :immediate-check="false" :offset="50" finished-text="">
        <div @click="goUserPlanDetail(item)" v-for="item in history" class="item row-start-start" :key="item.userTeachingTemplateId">
          <div class="row-between-center item-left-box">
            <div class="item-left">
              <div class="row-between-center">
                <h3 class="f14">{{ item.months }}个月 · 每周{{ item.weekCount }}次</h3>
              </div>
              <p class="color3 row-start-center">
                <span class="health-tag gray" v-for="label in item.labelList" :key="label">{{ label }}</span>
              </p>
              <div class="create-date row-start-end">
                <img :src="item.createTeacherPic" alt="">
                <span>{{ item.createTeacherName }} 创建于 {{ item.startTime }} (方案时效{{ item.useDays }}天)</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>


    </div>
    <div v-if="!curCase.requirementImage && !history.length" class="empty row-center-center">
      <van-empty image-size="21vw" image="https://img.chaolu.com.cn/PAD/icon/empty.png">
        <p class="f16" style="color: #6C727A">该会员未制定用户需求报告</p>
        <div @click="createUserPlan" class="create-btn2 bold row-center-center">进入运动规划</div>
      </van-empty>
    </div>

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appOpenWeb, webAppShare } from '@/lib/appMethod'
import { createUserPlan, goUserPlanDetail, getAuthorization } from './ipadMethod'
import { getParam } from '@/lib/utils'
import wx from 'weixin-js-sdk'


export default {
  mixins: [userMixin],
  components: {

  },
  data() {
    return {
      curCase: '', // 当前方案
      history: [], // 历史

      authorization: '',
      current: 1,
      loading: false,
      finished: false
    }
  },

  async created() {
    // 初始化
    await this.$getAllInfo(['teacherUserId', 'teacherToken'])
    initBack()

    this.userId_ = getParam().userId
    this.authorization = await getAuthorization()
    console.log('create', this.authorization);

    this.getList()
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
        initBack()
        this.getList()
      }
    })
  },
  methods: {
    newAppBack,

    getList() {

      this.$axios.post(`${this.baseURLApp}/ipad/user/teaching/template/listUserHistoryTemplate`, {
        userId: this.userId_,
        authorization: this.authorization,
        current: this.current,
        size: 10,
      }).then(res => {

        this.curCase = res.data.current || {}
        this.history = this.history.concat(res.data.history || [])

        this.loading = false
        this.current++
        if (res.data.history < 10) {
          this.finished = true
        }
      }).catch(r => {
        this.finished = true
      })
    },

    goAccess() {
      appOpenWeb('', `${window.location.origin}/#/talk-order/questionnaire`)
    },
    goCase(item) {
      appOpenWeb('', `${window.location.origin}/#/talk-order/case?userTeachingTemplateId=${item.userTeachingTemplateId}`)
    },
    getShare(item) {
      webAppShare('3', {
        title: '查看您的运动方案',
        image: 'https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_mini.png',
        path: `/pages/webView/index?webUrl=${window.location.origin}/#/talk-order/case&userTeachingTemplateId=${item.userTeachingTemplateId}`,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      })
    },
    createUserPlan() {
      createUserPlan(this.userId_)
    },
    goUserPlanDetail(item) {
      goUserPlanDetail(item.userTeachingTemplateId)
    }
  },

}
</script>

<style scoped lang="less">
.pages {
  background: #F5F6FA;
  min-height: 100vh;
}

.empty {
  min-height: calc(100vh - 160px);
}

.nav-bar {
  width: 750px;
  padding: calc(15px + env(safe-area-inset-top)) 20px 15px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .nav-bar-left {
    width: 120px;
    font-size: 24px;
  }

  .nav-bar-title {
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    width: 470px;
  }

  .nav-bar-right {
    width: 120px;
  }
}

.nav-box {
  background-color: white;
  padding: 0 23px;
  height: 63px;
  position: relative;

  &:after {
    content: '';
    width: 63px;
    height: 3px;
    border-radius: 2px 2px 0 0;
    background: #FFDE00;
    position: absolute;
    bottom: 0;
    transition: left 0.3s;
    margin-left: -31px;
  }

  div {
    width: 234px;
    height: 63px;
    font-size: 16px;
    color: #3C454E;

    &.active {
      font-weight: bold;
    }
  }

  &.new-people {
    &:after {
      left: 140px;
    }
  }

  &.member {
    &:after {
      left: 374px;
    }
  }

  &.activity {
    &:after {
      left: 608px;
    }
  }
}

.second-nav {
  padding: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: white;

  div {
    height: 43px;
    border-radius: 4px;
    padding: 0 16px;
    color: #3C454E;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #EEEEEE;
    background: #F9FAFB;

    &+div {
      margin-left: 20px;
    }

    &.active {
      background: #3665BD;
      color: white;
      border-color: #3665BD;
    }
  }
}

.list {
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 0 20px 30px;

  .item {
    padding: 25px 23px;
    background: white;
    border-radius: 8px;
    font-size: 14px;

    &+.item {
      margin-top: 20px;
    }

    img {
      width: 117px;
      height: 117px;
        border-radius: 12px;
        object-fit: cover;
    }

    .item-left-box {
      flex-grow: 1;
      //padding-left: 20px;
    }

    .item-left {
      flex-grow: 1;

      &>p {
        margin-top: 14px;
        flex-wrap: wrap;
        line-height: 40px;

        .health-tag {
          width: fit-content;
          padding: 6px 8px;
          background: rgba(252, 44, 17, 0.05);
          border-radius: 4px;
          line-height: 16px;
          font-size: 15px;
          color: #FC2C11;
          font-weight: 500;
          margin-bottom: 8px;
          flex-shrink: 0;
          margin-right: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          &.gray {
            color: #242831;
            background: #F5F6FA;
          }
        }
      }

      &>div>h3 {
        color: #242831;
        font-size: 21px;
        line-height: 21px;
      }

      .tips {
        display: inline-block;
        margin-top: 9px;
        color: #FFFFFF;
        padding: 2px 8px;
        line-height: 16px;
        border-radius: 4px;
        background: linear-gradient(270deg, #FC2C11 0%, #FC7411 100%);
        font-size: 14px;
      }
    }

    .share-btn {
      height: 29px;
      border-radius: 4px;

      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      background: url("https://img.chaolu.com.cn/PAD/icon/wechat_white.png") no-repeat 9px center #07C160;
      background-size: 18px 18px;
      padding-left: 30px;
      padding-right: 9px;
      box-sizing: border-box;
    }

    &+.item {
      //margin-top: 12px;
    }
  }

  .create-date {
    margin-top: 22px;

    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 6px;
    }

    span {

      line-height: 14px;
      color: #242831;
      font-size: 14px;
    }
  }
}

.popup-box {
  width: 391px;
  height: 436px;
  background: #FFFFFF;
  border-radius: 10px;

  h4 {
    padding: 43px 0 10px;
    text-align: center;
    font-size: 20px;
    color: #242831;
  }

  img {
    width: 350px;
    display: block;
    margin: 0 auto;
  }
}

.result-close {
  width: 31px;
  height: 31px;
  margin: 31px auto 0;
  display: block;
}









.create-btn {
  width: 113px;
  height: 39px;
  background: #FFDE00;
  border-radius: 6px;
  font-size: 14px;
  color: #242831;
}

.create-btn2 {
  width: 201px;
  height: 53px;
  background: #FFDE00;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 40px;
}

.bold {
  font-weight: bold;
}

.separate {
  font-size: 14px;
  line-height: 14px;
  color: #9AA1A9;
  margin: 58px 0 28px;
  text-align: center;
}</style>
